<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Reservierungen</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/reservations/new" class="button button-orange button-small">Reservierung hinzufügen ›</router-link></p>
            </div>
          </header>

          <div class="admin-body">

            <div class="search-wrap">
              <input v-model="search_phrase" v-on:input="search_handler" type="text" class="search-field" placeholder="Suchbegriff eingeben...">
            </div>

            <div v-if="loading">
              <div class="loading-wrap">
                <div class="loading-spinner"></div>
              </div>
            </div>
            <div v-else>

              <div class="filters">
                <p>
                  <strong>Filter:</strong>
                  <a @click="get_reservations(1, 'booked')" href="#">Offen</a>
                  <a @click="get_reservations(1, 'paid')" href="#">Bezahlt</a>
                  <a @click="get_reservations(1, 'canceled')" href="#">Storniert</a>
                  <a @click="get_reservations(1, 'confirmed')" href="#">Bestätigt</a>
                  <a @click="get_reservations(1, 'ended')" href="#">Beendet</a>
                </p>
              </div>

              <div v-if="search_loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>

                <table class="table">
                  <thead>
                    <tr>
                      <th width="10%">Status</th>
                      <th width="25%">Reserviert am</th>
                      <th width="25%">Abholung<br>Rückgabe</th>
                      <th width="10%">Fahrzeug</th>
                      <th width="10%">Kunde</th>
                      <th width="10%">Betrag</th>
                      <th width="10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="reservation in reservations" v-bind:key="reservation.id">
                      <td>
                        <span v-if="reservation.status == 'booked'" class="badge badge-yellow">Offen</span>
                        <span v-if="reservation.status == 'paid'" class="badge badge-green">Bezahlt</span>
                        <span v-if="reservation.status == 'canceled'" class="badge badge-red">Storniert</span>
                        <span v-if="reservation.status == 'confirmed'" class="badge badge-green">Bestätigt</span>
                        <span v-if="reservation.status == 'ended'" class="badge badge-black">Beendet</span>
                      </td>
                      <td>{{ reservation.created_at_formatted }}</td>
                      <td>{{ reservation.pickup_timestamp }}<br>{{ reservation.return_timestamp }}</td>
                      <td>
                        <span v-if="reservation.vehicle">{{ reservation.vehicle.license_plate }}</span>
                      </td>
                      <td><span v-if="reservation.company">{{ reservation.company }}<br></span>{{ reservation.first_name }} {{ reservation.last_name }}</td>
                      <td>{{ parseFloat(reservation.sum_gross).toFixed(2) }} €</td>
                      <td style="text-align:right">
                        <router-link :to="'/admin/reservations/'+reservation.id">
                          <span class="material-icons">create</span>
                        </router-link>
                        <a v-if="user.admin" @click.prevent="delete_reservation(reservation.id)">
                          <span class="material-icons">delete</span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-if="pagination.links">
                  <div class="row row-gutter-20">
                    <div class="col-12" style="text-align:left">
                      <p><a v-if="pagination.links.prev" @click="next_page(pagination.links.prev)">‹ Vorherige Seite</a></p>
                    </div>
                    <div class="col-12" style="text-align:right">
                      <p><a v-if="pagination.links.next" @click="next_page(pagination.links.next)">Nächste Seite ›</a></p>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'
import moment from 'moment';

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      loading: true,
      reservations: [],
      search_phrase: [],
      search_loading: false,
      user: {},
      pagination: {}
    }
  },
  methods: {
    moment: function () {
      return moment();
    },
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/user', { headers: { Authorization: this.$store.getters.getToken } })
      .then(response => {
        this.user = response.data.user;
      })

      this.get_reservations("1");

    },
    get_reservations(page, status) {
      this.loading = true;
      var params = null
      if (status) {
        params = {
          page: page,
          status: status
        }
      } else {
        params = {
          page: page
        }
      }
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/reservations', {
        params,
        headers: { Authorization: this.$store.getters.getToken }
      })
      .then(response => {
        response.data.reservations.forEach(value => {
          value.created_at_formatted = moment(value.created_at).format("DD.MM.YYYY HH:MM");
          value.pickup_date_formatted = moment(value.pickup_date).format("DD.MM.YYYY");
          value.return_date_formatted = moment(value.return_date).format("DD.MM.YYYY");
          value.pickup_time_formatted = moment(value.pickup_time).format("HH:mm");
          value.return_time_formatted = moment(value.return_time).format("HH:mm");
        });
        this.reservations = response.data.reservations;
        this.pagination = response.data.meta.pagination;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })
    },
    next_page(page) {
      this.loading = true;
      this.get_reservations(page.split("=")[1]);
    },
    search_handler() {
      if (this.search_loading == false) {
        this.search_loading = true;
        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/reservations?search_phrase='+this.search_phrase, { headers: { Authorization: this.$store.getters.getToken } })
        .then(response => {
          this.reservations = response.data.reservations;
          response.data.reservations.forEach(value => {
            value.created_at_formatted = moment(value.created_at).format("DD.MM.YYYY HH:MM");
            value.pickup_date_formatted = moment(value.pickup_date).format("DD.MM.YYYY");
            value.return_date_formatted = moment(value.return_date).format("DD.MM.YYYY");
            value.pickup_time_formatted = moment(value.pickup_time).format("HH:mm");
            value.return_time_formatted = moment(value.return_time).format("HH:mm");
          })
          this.pagination = response.data.meta.pagination;
          this.search_loading = false;
        })
        .catch(error => {
          console.log(error);
        })
      }
    },
    delete_reservation(id) {
      if (confirm('Sind Sie sicher, dass Sie diese Reservierung löschen möchten?')) {
        console.log(id);

        axios.delete(process.env.VUE_APP_BASE_API+'/v1/admin/reservations/'+id, { headers: { Authorization: this.$store.getters.getToken }})
        .then(response => {
          console.log(response);
          this.get_data();
        })
        .catch(error => {
          console.log(error);
        })

      }
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">
  a {
    cursor: pointer;
  }

  .filters {
    text-align: right;

    strong,
    a {
      display: inline-block;
    }

    a {
      font-weight: 600;
      color: #FF9000;
      margin-left: 8px;
    }
  }
</style>
